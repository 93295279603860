$(document).ready(function() {
    var cityID, managerID, cityFilter = "", managerFilter = "";
    $('.btn-offer').on('click', function(e) {
        e.preventDefault();
        cityID = $('#city-select option:selected').val();
        if ($('#manager-select')[0] === undefined) {
            managerID = '';
        } else {
            managerID = $('#manager-select option:selected').val();
        }

        if (cityID === '0') {
            cityFilter = "";
        } else {
            cityFilter = '[data-city="'+cityID+'"]';
        }

        if (managerID === '') {
            managerFilter = "";
        } else {
            managerFilter = '[data-manager="'+managerID+'"]';
        }

        $('.offer-item').hide();
        $('.offer-item'+cityFilter+managerFilter).show();

        if ($('#manager-select')[0] !== undefined) {
            $('h3 span.manager').text($('#manager-select option:selected').text());
        }

        if (cityID === '0') {
            $('h3.all').show();
            $('h3.city').hide();
        } else {
            $('h3.all').hide();
            $('h3.city span.city').text($('#city-select option:selected').text());
            $('h3.city').removeClass('hidden').show();
        }

        $('h3.null').hide();

        setNullHeader();

        $('html, body').animate({
            scrollTop: $("section.offers").offset().top
        }, 1000);
    });

    function setNullHeader() {
        if ($('.offer-item:visible').length === 0) {
            $('h3.all').hide();
            $('h3.city').hide();
            $('h3.null').removeClass('hidden').show();
        }
    }

    setNullHeader();
});
