$(document).ready(function() {
  $('.selectpicker').selectpicker({
    style: 'job-search-select',
    size: 10
  });
    
    $('.carousel-meet-us').carousel({
        interval:5000
  });


});
